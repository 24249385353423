<template>
  <div class="row mx-0 justify-content-center" >
    <div class="col-12">
      <div class="row mx-0 justify-content-center">
        <div class="col-12 mb-4 text-center font19" >
          Select Donation Frequency
        </div>
        <div class="col-12 mb-4 text-center" >
          Select how often you would like<br/>to make this recurring donation:
        </div>
      </div>
      <div class="row mx-0 justify-content-center" v-for="theFrequency in recurringOptions" :key="theFrequency">
        <div class="col-11 col-sm-6 col-md-6 col-lg-5 col-xl-5 col-xxl-4 mb-2 text-capitalize" >
          <Button class="text-capitalize " width="100" :btnText="displayText(theFrequency)" @buttonClicked="setFrequency(theFrequency)" :class="{'green_light': valueFrequency === theFrequency, green: valueFrequency !== theFrequency}" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { DateTime } from 'luxon'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue'))
  },
  name: 'CreditCard',
  props: {
    startDate: {
      type: Number,
      required: true
    },
    frequency: {
      type: String,
      required: true
    },
    debitOrderDay: {
      type: Number,
      required: true
    }
  },
  emits: ['update:startDate', 'update:debitOrderDay', 'update:frequency'],
  data () {
    return {
      recurringOptions: ['DAILY', 'WEEKLY', 'MONTHLY']
    }
  },
  computed: {
    valueFrequency: {
      get () {
        return this.frequency
      },
      set (value) {
        this.$emit('update:frequency', value)
      }
    },
    valueStartDate: {
      get () {
        return this.startDate
      },
      set (value) {
        this.$emit('update:startDate', value)
      }
    },
    valueDebitOrderDay: {
      get () {
        return this.debitOrderDay
      },
      set (value) {
        this.$emit('update:debitOrderDay', value)
      }
    }
  },
  async mounted () {
    await this.setStartDate()
    await this.setDay()
  },
  methods: {
    displayText (val) {
      return val.toLowerCase()
    },
    setFrequency (val) {
      this.valueFrequency = val
    },
    setStartDate () {
      this.valueStartDate = DateTime.now().plus({ days: 1 }).startOf('day').toMillis()
    },
    setDay () {
      if (this.valueStartDate) {
        const theDate = DateTime.fromMillis(this.valueStartDate)
        const theDay = theDate.toFormat('d')
        this.valueDebitOrderDay = Number(theDay)
      }
    }
  }
}
</script>
